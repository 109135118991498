<template>
  <div>
    <a-modal
        :width="700"

        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-form-item label='Title'>
          <a-input
              v-decorator="[
              'title',
               { rules: [{ required: true, message: 'Title is required' }] ,
               initialValue:selectedItem?selectedItem.title:'',
              }
            ]"
          />
        </a-form-item>
        <a-form-item label='Description'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
                  type='textarea'
              placeholder="Write short description about report reason"
              v-decorator="['description',{
                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',
              }]"
          />
        </a-form-item>
        <a-form-item class='collection-create-form_last-form-item'>
          <a-radio-group
              v-decorator="[
              'status',
              {
                initialValue: Object.keys(selectedItem).length!=0?selectedItem.status.toString():'true',
              }
            ]"
          >
            <a-radio value='true'>Active</a-radio>
            <a-radio value='false'>In-active</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ['editMode', 'visible','selectedItem'],
  data() {
    return {
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''

      // visible: false,
    };
  },
  computed: {
    title() {
      return this.editMode ? 'Edit Report Reason' : 'Create New Report Reason'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.id
          this.confirmLoading = true
          if (this.editMode){
            this.$store.dispatch("UPDATE_REASON", values)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_REASON", values)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },

    cancelForm(){
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
  },
  mounted() {
      if (this.editMode&&Object.keys(this.selectedItem).length!=0){
        this.id=this.selectedItem._id
    }
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
</style>