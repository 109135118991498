var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":700,"destroyOnClose":true,"confirm-loading":_vm.confirmLoading,"dialog-style":{ top: '20px' },"visible":_vm.visible,"title":_vm.title,"okText":"Submit"},on:{"ok":_vm.handleOk,"cancel":_vm.cancelForm}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleOk}},[_c('a-form-item',{attrs:{"label":"Title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
             { rules: [{ required: true, message: 'Title is required' }] ,
             initialValue:_vm.selectedItem?_vm.selectedItem.title:'',
            }
          ]),expression:"[\n            'title',\n             { rules: [{ required: true, message: 'Title is required' }] ,\n             initialValue:selectedItem?selectedItem.title:'',\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Description"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description',{
              initialValue:Object.keys(_vm.selectedItem).length!=0?_vm.selectedItem.description:'',
            }]),expression:"['description',{\n              initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',\n            }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 6 },"type":"textarea","placeholder":"Write short description about report reason"}})],1),_c('a-form-item',{staticClass:"collection-create-form_last-form-item"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'status',
            {
              initialValue: Object.keys(_vm.selectedItem).length!=0?_vm.selectedItem.status.toString():'true',
            }
          ]),expression:"[\n            'status',\n            {\n              initialValue: Object.keys(selectedItem).length!=0?selectedItem.status.toString():'true',\n            }\n          ]"}]},[_c('a-radio',{attrs:{"value":"true"}},[_vm._v("Active")]),_c('a-radio',{attrs:{"value":"false"}},[_vm._v("In-active")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }